import React from "react";
export function Litmus() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="2500" height="2500" preserveAspectRatio="xMidYMid" viewBox="0 0 256 256">
          <path fill="#6A7A55" d="M0 127.94c0 12.487 1.799 24.552 5.133 35.961l70.62-23.131a53.012 53.012 0 0 1 .465-25.778L5.095 92.109A127.964 127.964 0 0 0 0 127.94" />
          <path fill="#A09440" d="M93.597 87.948L48.946 27.277C30.192 42.024 15.664 61.898 7.466 84.785l71.289 22.937a53.225 53.225 0 0 1 14.842-19.774" />
          <path fill="#AFA148" d="M124.211 75.836V0C98.583.745 74.848 9.027 55.136 22.7l44.725 60.772a52.746 52.746 0 0 1 24.35-7.636" />
          <path fill="#4C5C59" d="M78.044 148.12L7.512 171.222c8.316 23.145 23.104 43.203 42.191 57.981l43.531-59.892a53.18 53.18 0 0 1-15.19-21.191" />
          <path fill="#48555D" d="M55.934 233.725c19.554 13.346 42.993 21.419 68.277 22.155v-74.152a52.752 52.752 0 0 1-24.752-7.888l-43.525 59.885" />
          <path fill="#E3B23B" d="M200.148 22.212C180.609 8.854 157.182.763 131.909.005v75.926a52.764 52.764 0 0 1 23.527 7.798l44.712-61.517" />
          <path fill="#903024" d="M131.909 181.633v74.242c25.471-.764 49.068-8.976 68.699-22.522l-44.245-60.119a52.75 52.75 0 0 1-24.454 8.399" />
          <path fill="#D65841" d="M162.495 168.577l44.317 60.217c19.033-14.892 33.734-35.058 41.928-58.294l-71.649-23.052a53.147 53.147 0 0 1-14.596 21.129" />
          <path fill="#E6703E" d="M256 127.94c0-12.642-1.84-24.852-5.254-36.386l-72.044 23.597a52.997 52.997 0 0 1 .579 24.914l71.796 23.1A128.022 128.022 0 0 0 256 127.94" />
          <path fill="#F4A83A" d="M176.188 107.874l72.154-23.634c-8.332-22.937-23.032-42.818-41.967-57.501l-44.718 61.526a53.203 53.203 0 0 1 14.531 19.609" />
        </svg>;
}
  